import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "./Test.css";
import PricingYearlyTwo from "./PricingYearlyTwo";
import PricingMonthlyTwo from "./PricingMonthlyTwo";

const Pricingtwo = () => {
  
  return (
    <div>
      <Tabs>
        <TabList className="nav nav-tabs justify-content-center pricing-nav-two pricing-nav-two-custom">
          <Tab>INR ₹</Tab>
          <Tab >
            USD $
          </Tab>
        </TabList>

        <div className="tab-content-wrpper">
        <TabPanel>
            <PricingMonthlyTwo />
          </TabPanel>
          <TabPanel>
            <PricingYearlyTwo />
          </TabPanel>
          {/* End Y Year */}
        </div>
      </Tabs>
    </div>
  );
};

export default Pricingtwo;
