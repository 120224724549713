import React, { useContext, useEffect } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import PricingMonthly from "./PricingMonthly";
import PricingYearly from "./PricingYearly";
import "./Test.css";
import { Storecontext } from "../../../Context/Storecontext";

const Pricing = () => {
  const {setshowY,showY} = useContext(Storecontext);
  
  useEffect(() => {
    const data = window.localStorage.getItem("showingprice");
    if (data !== null) {
      setshowY(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("showingprice", JSON.stringify(showY));
  }, [showY]);

  const reload = () => {
    setshowY(true);
    window.location.reload();
  };

  useEffect(() => {
    // Load PayPal SDK script (only one time)
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=BAAT_X5JzKV-i0-c6JXVtNTvfKwYjZnMBheBLRCFivCNahV9nPG9ePutt4lhvE4gmQEiiXZ2jBgG7gdH6c&components=hosted-buttons&disable-funding=venmo&currency=USD";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    // Render PayPal hosted button after script is loaded
    script.onload = () => {
      //Render first PayPal Button
      window.paypal
        .HostedButtons({
          hostedButtonId: "63TBMY32LV7ZE",
        })
        .render(".paypal-container");

      //Render second PayPal Button
      window.paypal
        .HostedButtons({
          hostedButtonId: "JYYL3G4M2SHVE",
        })
        .render(".paypal-container1");
      // Cleanup function to remove script after component unmounts
      return () => {
        document.body.removeChild(script);
      };
    };
  }, []);
  return (
    <div>
      <Tabs selectedIndex={showY ? 1 : 0}>
        <TabList className="nav nav-tabs justify-content-center pricing-nav-two pricing-nav-two-custom">
          <Tab onClick={() => setshowY(false)}>INR ₹</Tab>
          <Tab onClick={reload} selected>
            USD $
          </Tab>
        </TabList>

        <div className="tab-content-wrpper">
          {showY ? <PricingYearly /> : <PricingMonthly />}

          {/* End Y Year */}
        </div>
      </Tabs>
    </div>
  );
};

export default Pricing;
