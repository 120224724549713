import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ClientDemo from "../../../components/demo/ClientDemo";

const Overview = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          ClientCtrl Stress-free Customer Management Software
        </title>
        <meta name="description" content="Enhance Productivity with our intuitive Customer Management Software. Take control of deals, leads, clients, orders, and invoices effortlessly." />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}  
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 col-md-10 m-auto">
                <h1 className="heading">Modern Customer Management System</h1>
                <p className="sub-heading" style={{paddingBottom:'30px'}}>
                  ClientCtrl is crafted with the needs of small and medium-sized businesses in focus, providing comprehensive customer management solutions in a single all-in-one software.
                </p>
                <ClientDemo />
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven" style={{paddingTop:'0'}}>
        <div className="container">
          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/leads.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>LEAD MANAGEMENT</h6>
                    <h3 className="title font-gilroy-bold">
                      Streamline your sales process & boost conversions
                    </h3>
                    <p className="font-rubik">
                      Effortlessly access all your leads in one place. Easily filter them on specific criteria, ensuring you're always focused on what matters most. Add follow-up records, view activity history, get reminders and much more.
                    </p>

                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/deals.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>DEALS</h6>
                    <h3 className="title font-gilroy-bold">
                      Master deals like a pro with ClientCtrl Software
                    </h3>
                    <p className="font-rubik">
                      Unlock the secret to effortless deal management and boost your sales performance with our intuitive software. Visualize and track all your deals in one place and close deals faster.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/client Details.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>CLIENTS</h6>
                    <h3 className="title font-gilroy-bold">
                      Simple, Easy Small Business Customer Management
                    </h3>
                    <p className="font-rubik">
                      From viewing customer orders and subscriptions to tracking dues, total income, communication history, deals, support tickets, and client contacts, ClientCtrl puts everything you need right at your fingertips.
                    </p>

                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine" id="cp">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/clientportal2.png " alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>CLIENT PORTAL</h6>
                    <h3 className="title font-gilroy-bold">
                      Empower Customers with Self-Service Portal
                    </h3>
                    <p className="font-rubik">
                      Client Portal allows your clients to view their orders, delve into subscription details, and track their payment history effortlessly. They can make purchases online, reach out your helpdesk for any assistance.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>

              </div>

            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/communication.png" alt="illustrator" style={{height:'600px',paddingLeft:'50px'}}/>
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>COMMUNICATION</h6>
                    <h3 className="title font-gilroy-bold">
                      Efficient Communication for Sales Outreach
                    </h3>
                    <p className="font-rubik">
                      Make phone calls, send emails, WhatsApp messages, and SMS – all from within the portal. ClientCtrl keeps track of every interaction,
                      giving you access to a comprehensive communication history.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>

            </div>
          </div>
          {/* /.block-style-nine */}
          <div className="block-style-nine">
            <div className="row align-items-center">
              <div
                className="col-lg-7 col-md-9 m-auto order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/assets/notification.png" alt="illustrator" />
                </div>{" "}
                {/* /.illustration-holder */}
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper" id="nf">
                  <h6>NOTIFICATIONS</h6>
                  <h3 className="title font-gilroy-bold">
                    Always stay in the loop with Notifications
                  </h3>
                  <p className="font-rubik">
                    Receive real-time notifications for various lead/client events. Whether it's upcoming follow-up meetings or a task,
                    ClientCtrl keeps you informed every step of the way through multiple modes including in-app notifications.
                  </p>
                </div>{" "}
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>{" "}
          {/* /.block-style-nine */}
          <div className="block-style-nine">
            <div className="row align-items-center">
              <div
                className="col-lg-7 col-md-9 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/assets/Automation.png" alt="illustrator" />
                </div>{" "}
                {/* /.illustration-holder */}
              </div>
              <div
                className="col-lg-5"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>MARKETING AUTOMATION</h6>
                  <h3 className="title font-gilroy-bold">
                    Engage with your leads automatically with Automation
                  </h3>
                  <p className="font-rubik">
                    Imagine reaching out to your leads at just the right moment, with personalized messages that capture their attention and drive action. With ClientCtrl,
                    you can effortlessly create email and WhatsApp sequences.
                  </p>

                </div>{" "}
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>{" "}
          {/* /.block-style-nine */}
          <div className="block-style-nine">
            <div className="row align-items-center">
              <div
                className="col-lg-7 col-md-9 m-auto order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/assets/shop.png" alt="illustrator" />
                </div>
                {/* /.illustration-holder */}
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>SHOP</h6>
                  <h3 className="title font-gilroy-bold">
                    Easy to use Online Shop feature tailored for clients
                  </h3>
                  <p className="font-rubik">
                    Client Portal allows your clients to view their orders, delve into subscription details, and track their payment history effortlessly. They can make purchases online, reach out your helpdesk for any assistance.
                  </p>
                </div>{" "}
                {/* /.text-wrapper */}
              </div>

            </div>

          </div>
          <div className="block-style-nine" >
            <div className="row align-items-center">
              <div
                className="col-lg-7 col-md-9 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/assets/order.png" alt="illustrator" />
                </div>{" "}
                {/* /.illustration-holder */}
              </div>
              <div
                className="col-lg-5"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>ORDERS</h6>
                  <h3 className="title font-gilroy-bold">
                    Streamline order management workflow
                  </h3>
                  <p className="font-rubik">
                    Streamline your workflow and effortlessly oversee every aspect of your orders from start to finish. you can easily track order statuses,
                    manage inventory, setup, subscriptions and ensure timely deliveries.
                  </p>

                </div>{" "}
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>{" "}
          {/* /.block-style-nine */}
          <div className="block-style-nine">
            <div className="row align-items-center">
              <div
                className="col-lg-7 col-md-9 m-auto order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/assets/product_and_services.png" alt="illustrator" />
                </div>
                {/* /.illustration-holder */}
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>PRODUCTS</h6>
                  <h3 className="title font-gilroy-bold">
                    Create unlimited products, services, subscriptions
                  </h3>
                  <p className="font-rubik">
                    Revolutionize your business with our platform that allows you to effortlessly create an infinite array of products, services,
                    and subscriptions. Say goodbye to restrictions and say hello to endless possibilities.
                  </p>
                </div>{" "}
                {/* /.text-wrapper */}
              </div>

            </div>

          </div>
          <div className="block-style-nine">
            <div className="row align-items-center">
              <div
                className="col-lg-7 col-md-9 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="illustration-holder">
                  <img src="images/assets/reports.png" alt="illustrator" />
                </div>{" "}
                {/* /.illustration-holder */}
              </div>
              <div
                className="col-lg-5"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>REPORTS</h6>
                  <h3 className="title font-gilroy-bold">
                    Comprehensive reports to make informed decisions
                  </h3>
                  <p className="font-rubik">
                    Unlock business insights instantly with comprehensive reports on sales, invoices, clients, leads, and staff performance.
                    Stay ahead of the competition by gaining valuable insights into lead follow-ups and staff productivity.
                  </p>

                </div>{" "}
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="fancy-short-banner-two pt-130 md-pt-70">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                      bg-wrapper
                      d-lg-flex
                      align-items-center
                      justify-content-between
                    "
              >
                <h2 className="font-gilroy-bold">
                  Take the first step towards efficiency today!
                </h2>
                <ClientDemo style={{ height: '70px', width: '200px', fontSize: '20px', border: '1px solid white' }} />
                {/* <ClientDemo style={{ height: '70px', width: '200px',fontSize: '20px',background:'white',color:'#7034ff',border:'1px solid black'}}/> */}
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>

      </div>


      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default Overview;
