import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
import "./Blogshow.css";
import { useNavigate } from "react-router-dom";


function Blogadd() {
const navigate = useNavigate(); 

  const token = localStorage.getItem("token");
  const url = process.env.REACT_APP_API_URL;
  const [data, setdata] = useState({
    title: "",
    url: "",
    introduction: "",
    content: "",
    metaDiscription: "",
    metaTitle: "",
    metaKeywords: "",
  });
  const onChangehanndler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "title") {
      setdata((data) => ({ ...data, [name]: value }));
      const slug = createSlug(value);
      data.url = slug; // Output: "this-is-a-blog-title"
    } else {
      setdata((data) => ({ ...data, [name]: value }));
    }
  };
  const onChangeckintroHandler = (event, editor) => {
    const value = editor.getData();

    // Update the state with the new value
    setdata((prevData) => ({ ...prevData, introduction: value }));
  };
  const onChangeckcontentHandler = (event, editor) => {
    const value = editor.getData();
    // Update the state with the new value
    setdata((prevData) => ({ ...prevData, content: value }));
  };
  const onsubmithandler = async (event) => {
    event.preventDefault();
    const response = await axios.post(`${url}/api/user/addArticle`, data, {
      headers: { token },
    });
    if (response.data.success) {
      setdata({
        title: "",
        url: "",
        introduction: "",
        content: "",
        metaDiscription: "",
        metaTitle: "",
        metaKeywords: "",
      });
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };

  function createSlug(title) {
    return title
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9\s-]/g, "") // Remove all non-alphanumeric chars except spaces and hyphens
      .trim() // Trim leading/trailing spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single one
  }

  return (
    <div className="container mt-5">
   
    <button className="btn btn-outline-primary my-2" onClick={() => navigate(`/blog-management/index`)}>
      <i className="fa fa-list mx-1"></i>
      List Blog
    </button>

      <ToastContainer />

      <form
        onSubmit={onsubmithandler}
        className="border p-4 rounded shadow-sm bg-light"
      >
        <h3 className="text-center mb-4">Add Article</h3>

        {/* Title */}
        <div className="form-group mb-3">
          <label className="required-field" htmlFor="title required-field">
            <i className="fa fa-heading me-2"></i>Title
          </label>
          <input
            type="text"
            name="title"
            required
            value={data.title}
            onChange={onChangehanndler}
            className="form-control"
            placeholder="Enter title" aria-required
          />
        </div>

        {/* URL */}
        <div className="form-group mb-3">
          <label className="required-field" htmlFor="url">
            <i className="fa fa-link me-2"></i>Slug
          </label>
          <input
            type="text"
            required
            name="url"
            value={data.url}
            onChange={onChangehanndler}
            className="form-control"
            placeholder="Enter URL"
          />
        </div>

        {/* Introduction */}
        <div className="form-group mb-3">
          <label className="required-field" htmlFor="introduction">
            <i className="fa fa-info-circle me-2"></i>Introduction
          </label>
          <CKEditor
            name="introduction"
            onChange={onChangeckintroHandler}
            data={data.introduction}
            editor={ClassicEditor}
            onInit={(editor) => {}}
            config={{
              ckfinder: {
                uploadUrl: `${url}/api/user/uploadFile`,
              },
            }}
            className="custom-ckeditor"
          />
        </div>

        {/* Content */}
        <div className="form-group mb-3">
          <label htmlFor="content">
            <i className="fa fa-align-left me-2"></i>Content
          </label>
          <CKEditor
            name="content"
            onChange={onChangeckcontentHandler}
            data={data.content}
            editor={ClassicEditor}
            onInit={(editor) => {}}
            className="custom-ckeditor"
            config={{
              ckfinder: {
                uploadUrl: `${url}/api/user/uploadFile`,
              },
            }}
          />
        </div>

        <div className="form-group mb-3">
          <div className="row">
            {/* Meta Title Input */}
            <div className="col-md-6">
              <label htmlFor="metaTitle">
                <i className="fa fa-pen-nib me-2"></i>Meta Title
              </label>
              <input
                type="text"
                name="metaTitle"
                value={data.metaTitle}
                onChange={onChangehanndler}
                className="form-control"
                placeholder="Enter meta title"
              />
            </div>

            {/* Meta Keywords Input */}
            <div className="col-md-6">
              <label htmlFor="metaKeywords">
                <i className="fa fa-key me-2"></i>Meta Keywords 
              </label>
              <input
                type="text"
                name="metaKeywords"
                value={data.metaKeywords}
                onChange={onChangehanndler}
                className="form-control"
                placeholder="Enter meta keywords"
              />
            </div>
          </div>
        </div>

        {/* Meta Description */}
        <div className="form-group mb-3">
          <label htmlFor="metaDescription">
            <i className="fa fa-file-alt me-2"></i>Meta Description
          </label>
          <textarea
            name="metaDiscription"
            onChange={onChangehanndler}
            value={data.metaDiscription}
            className="form-control"
            rows="4"
            placeholder="Enter meta description"
          />
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button type="submit" className="btn btn-primary px-5 py-2">
            <i className="fa fa-paper-plane me-2"></i>Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Blogadd;
