import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../../components/header/HeaderTwo";
import CounterFiveColored from "../../../../components/counter/CounterFiveColored";
import FooterTwo from "../../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";

const Documentation = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>About Us | ClientCtrl</title>
        <meta name="description" content="We have poured our hearts and souls into creating a platform that embodies our dedication to excellence. We strive to empower businesses of all sizes to build stronger relationships with their clients." />
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
		   Start Header
		   ============================================== */}
      <HeaderTwo />
      {/* =============================================
				Fancy Text block Seventeen
			============================================== */}
      <div className="fancy-text-block-seventeen mt-100 pt-20 md-mt-40">
      <div className="container">
      <h1 className="heading" style={{fontFamily:"gilroy-black",fontSize:"84px",paddingTop:'10px'}}>
      <span>
      About us{" "}
      <img src="images/shape/line-shape-3.svg" alt="shape" />
      </span>
      </h1>
          <div className="row">
            {/* <div
              className="col-lg-5 ms-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="title-style-four">
                <div className="row">
                  <div className="col-lg-12 col-md-8">
                    
                      <img 
                          src="images/assets/about.jpg"
                          alt="media"
                          className="img-meta"
                      />
                  
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* End .col */}
            <div
              className="col-xl-12 col-lg-7 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <p className="text-meta" style={{fontSize:"23px"}}>
                At Sash Info Services Pvt. Ltd., we're a collective of passionate technology enthusiasts and marketing strategists
                united by a shared mission: to empower businesses with the tools and strategies they need to thrive in the
                ever-changing digital landscape.
              </p>
              <p className="text-meta" style={{fontSize:"23px"}}>
               We hail from Bangalore, India's vibrant tech hub, and our team boasts over 10+ years of experience crafting innovative software
               solutions and results-driven digital marketing campaigns. Our vision is bold. We aim to help build a world where businesses,
               regardless of size or industry,
               can access the resources and expertise required to bridge the gap between their aspirations and reality.
              </p>
              <p className="text-meta" style={{fontSize:"23px"}}>
              Our team is a powerhouse of creativity and technical prowess. We're not just "techies" – we're problem-solvers,
               innovators, and digital marketing specialists. We are well-versed in cutting-edge software development and 
               website and website application development, along with extensive expertise in a wide range of digital marketing 
               services like SEO, web design, SMO, etc., to help you win the online marketing game. This holistic approach allows
              us to deliver impactful solutions that not only meet your needs but exceed your expectations.
              </p>
              <p className="text-meta" style={{fontSize:"23px"}}>
               We go beyond simply building software or executing marketing campaigns. We take the time to understand your unique business
               goals and challenges, then leverage our expertise to craft custom solutions that optimise your online presence, streamline your 
               operations, and propel you towards sustainable growth.
              </p>
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/*  /.fancy-text-block-seventeen */}

      {/* =============================================
				Fancy Text block Eighteen
			============================================== */}
      <div className="fancy-text-block-eighteen mt-20 md-mt-40">
        
        <div className="counter-info pt-50 pb-45 md-pt-40">
          <div className="container">
            <CounterFiveColored />
          </div>
        </div>

        <div className="fancy-short-banner-three mt-80 md-mt-40">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
        {/*  /.counter-info */}
        <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      </div>
      
    </div>
  );
};

export default Documentation;
