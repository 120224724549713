import React from "react";
import ClientDemo from "../demo/ClientDemo";


const CallToActionThree = () => {
  return (
    <div className="row align-items-center">
      <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "darkblue", fontStyle: "italic" }}>
            Transform your business with ClientCtrl
          </h6>
          <h2>Take the first step towards efficiency today!</h2>
        </div>
        {/* /.title-style-one */}
      </div>
      {/* End .col */}

      <div className="col-lg-4 col-md-12 col-sm-12">
        <div className="form-wrapper text-center">
          <ClientDemo style={{ height: '55px', width: '200px', fontSize: '20px', }}/>
          {/* End form */}
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default CallToActionThree;
