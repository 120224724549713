import React from "react";

const FeatureContent = [
  {
    icon: "20",
    meta: "Lead Forms",
    subTitle: `Gather website leads directly into your CRM with embedded forms. Boost your conversion rates.`,
    dataDelay: "0",
  },
  {
    icon: "21",
    meta: "Marketing Automation",
    subTitle: `Enhance lead nurturing with automated personalized Email/WhatsApp sequences. Close deals faster.`,
    dataDelay: "100",
  },
  {
    icon: "22",
    meta: "Better Communication",
    subTitle: `Streamline communication with leads & clients. Improve follow-ups tracking & increase team productivity.`,
    dataDelay: "300",
  },
  {
    icon: "213",
    meta: "360 degree Customer View",
    subTitle: `Empower your staff to make informed decisions quickly and effectively with all data at their fingertips.`,
    dataDelay: "0",
  },
  {
    icon: "209",
    meta: "Order Management",
    subTitle: `Effortlessly handle the sale of physical or digital products, services, recurring orders, payments, and shipping`,
    dataDelay: "100",
  },
  {
    icon: "210",
    meta: "Invoice Management",
    subTitle: `Effortlessly create sophisticated and professional invoices, consistently make a positive impact on your clients`,
    dataDelay: "300",
  },
  {
    icon: "212",
    meta: "Support Tickets",
    subTitle: `Intuitive support ticketing system that ensures your team can focus on resolving the client issues`,
    dataDelay: "0",
  },
  {
    icon: "214",
    meta: "Client Portal",
    subTitle: `Smooth shopping journey for clients and a portal to track their purchases, subscriptions & payments.`,
    dataDelay: "100",
  },
  {
    icon: "211",
    meta: "Comprehensive System",
    subTitle: `Content Management, Reports, Staff roles & permissions, business branches, multi-currency & more features.`,
    dataDelay: "300",
  },
];

const FeatureFour = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-five">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h6 className="title">
              <span>{val.meta}</span>
            </h6>
            <p style={{fontSize:"18px"}}>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFour;
