import React, { useEffect } from "react";
import { images } from "../../../assets/images/media/images";

const PricingContent = [
  
  {
    packName: "Starter",
    price: "16,499",
    del_price:  "₹32,999",
    billCycle: "₹3,999 per year from",
    year:'2',
    preFeatures: [
      "Unlimited Leads",
      "Unlimited Clients",
      "Unlimited Products",
      "3 Staff",
      "Single Location",
      "Deals",
      "Quotes",
      "Orders",
      "Invoices",
      "Support Tickets",
      "Analytics",
      "Client Portal",
      "Custom Domain",
      "Limited Branding",
      "5 GB Disk Space",
    ],
  },
  {
    packName: "Standard",
    price: "20,499" ,
    del_price:  "₹41,499",
    billCycle: "₹5,749 per year from",
    year:'2',
    preFeatures: [
      "Unlimited Leads",
      "Unlimited Clients",
      "Unlimited Products",
      "Unlimited Staff",
      "Unlimited Locations",
      "Deals",
      "Quotes",
      "Orders",
      "Invoices",
      "Support Tickets",
      "Analytics",
      "Client Portal",
      "Custom Domain",
      "Whitelabel Branding",
      "8 GB Disk Space",
    ],
  },
  
];

const PricingMonthly = () => {
  useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form");

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_OgMX6W3V3TqygC";
      rzpPaymentForm.appendChild(script);
    }
  });
  useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form2");

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_OgMa6yh5QqGiF5";
      rzpPaymentForm.appendChild(script);
    }
  });
  return (
    
    <div className="row no-gutters">

      {PricingContent.map((val, i) => (
        <div className="col-lg-6 col-sm-6 pr-bg d-flex" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price">
              <sup>₹</sup>
              {val.price } <span style={{fontSize:"40px"}}><sub><del>{val.del_price}</del></sub></span>
            </div>
            <div className="" style={{marginBottom:'10px',fontSize:'14px'}}>Taxes Extra<sup>*</sup></div>

              <p style={{fontSize:"26px",color:"black"}}> <b>one-time</b></p>
            <div className="d-flex justify-content-center align-items-center"><img
              src={images.time_limit}
              alt="media" style={{height:'35px',width:'35px'}} />&nbsp;<span style={{fontFamily:'gilroy-semibold',color:'orange'}}>Limited period offer!</span></div>
            <div className="bill-cycle" style={{marginBottom:'5px'}}>{val.billCycle} {val.year}<sup>nd</sup> year</div>
            {(i ?(<form id="rzp_payment_form2"></form>):(<form id="rzp_payment_form"></form>))}
            
            <ul style={{color:"black"}}>
              
              {val.preFeatures.map((list, i) => (
                <li key={i}><hr />{list}</li>
              ))}
            <hr />
            </ul>
          </div>
          {/* /.pr-table-wrapper  */}
        </div>
        
      ))}
      
    </div>
  );
};

export default PricingMonthly;
