import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS

const BlogView = () => {
const navigate = useNavigate(); 

  const token = localStorage.getItem("token");
  const { id } = useParams(); // Get the 'id' from URL params
  const [blogById, setBlogById] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    title: "",
    url: "",
    introduction: "",
    content: "",
    metaDiscription: "",
    metaTitle: "",
    metaKeywords: "",
    blogId: "",
  });

  const fetchBlogsById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/list/${id}`,
        { headers: { token } }
      );
      if (response.data.success) {
        setBlogById(response.data.blogs);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setError("Failed to fetch data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBlogsById(id); // Call function when 'id' from params is available
    }
  }, [id]);

  // Update `data` once `blogById` is fetched
  useEffect(() => {
    if (blogById) {
      setData({
        title: blogById.title || "",
        url: blogById.url || "",
        introduction: blogById.introduction || "",
        content: blogById.content || "",
        metaDiscription: blogById.metaDiscription || "",
        metaTitle: blogById.metaTitle || "",
        metaKeywords: blogById.metaKeywords || "",
        blogId: blogById._id || "",
      });
    }
  }, [blogById]);

  return (
    <div>
      {error && <p>{error}</p>}
      {blogById ? (
        <div className="container mt-5 mb-5 p-4">
          <button
            className="btn btn-outline-primary my-2"
            onClick={() => navigate(`/blog-management/index`)}
          >
            <i className="fa fa-list mx-1"></i>
            List Blog
          </button>
          {/* Toast for notifications */}
          <ToastContainer />

          {/* Blog Title */}
          <h3 className="text-center mb-4">
            <i className="fa fa-newspaper"></i> View Article
          </h3>

          {/* Title */}
          <h1 className="display-4 text-dark mb-4">{data.title}</h1>

          {/* URL */}
          <p className="text-primary mb-4">
            <i className="fa fa-link"></i> <a href={data.url}>{data.url}</a>
          </p>

          {/* Introduction */}
          <div className="mb-4">
            <div className="bg-light p-3 rounded">
              <h5>
                <div
                  className="bg-light p-3 rounded"
                  dangerouslySetInnerHTML={{ __html: data.introduction }}
                />
              </h5>
            </div>
          </div>

          {/* Content */}
          <div className="mb-4">
            <div className="bg-light p-3 rounded">
              <h5>
                <div
                  className="bg-light p-3 rounded"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </h5>
            </div>
          </div>

          {/* Meta Title */}
          <div className="mb-4">
            <h4>
              <i className="fa fa-align-left me-2"></i> Meta Title
            </h4>
            <p className="text-muted mb-2">
              {data.metaTitle || "N/A"}{" "}
              {/* Handle cases where data might be missing */}
            </p>
          </div>

          {/* Meta Keywords */}
          <div className="mb-4">
            <h4>
              <i className="fa fa-key me-2"></i> Meta Keywords
            </h4>
            <p className="text-muted mb-2">{data.metaKeywords || "N/A"}</p>
          </div>

          {/* Meta Description */}
          <div>
            <h4>
              <i className="fa fa-align-left me-2"></i> Meta Description
            </h4>
            <p className="text-muted">{data.metaDiscription || "N/A"}</p>
          </div>
        </div>
      ) : (
        <p>
          Loading...
          <ToastContainer />
        </p>
      )}
    </div>
  );
};

export default BlogView;
