import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import './Header.css'
// const logo = "images/logo/deski_06.svg";

const logo = "images/logo/clientctrl500.png";

const HeaderLanding = () => {
  const defaultStyle = {
    backgroundColor: "rgb(88, 0, 176)",
    color: "whitesmoke",
    border: "none",
    height: "48px",
    width: "150px",
    marginBottom: "50px",
  };
  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div className="theme-main-menu sticky-menu theme-menu-five fixed">
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <img
                src={logo}
                alt="brand"
                style={{ width: "53%", paddingLeft: "10px" }}
              />
            </Link>
          </div>
          {/* End logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header"></div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[]}
                    currentClassName="active"
                    offset={-500}
                  >
                    <li className="nav-item">
                      <a href="#features" className="nav-link">
                        <br />
                      </a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          <div className="right-widget  text-center ">
            <a href="https://bit.ly/4gDgppF" className="mediaBtn">
              <Button
                variant="warning"
                className="reqdemo m-auto"
                id="dm1"
                style={defaultStyle}
              >
                <b>Book A Demo</b>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderLanding;
