/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Storecontext } from "../../Context/Storecontext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS

const LoginPopup = () => {
  const { url, settoken, setshowLogin } = useContext(Storecontext);
  const [data, setdata] = useState({ name: "Admin", email: "", password: "" });
  const onchangehandller = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setdata((data) => ({ ...data, [name]: value }));
  };

  const onlogin = async (event) => {
    event.preventDefault();
    let newurl = url;

    newurl += "/api/user/login";

    const response = await axios.post(newurl, data);
    if (response.data.success) {
      settoken(response.data.token);
      localStorage.setItem("token", response.data.token);
      setshowLogin(false);
    } else {
      toast.error(response.data.message);
    }
  };

  
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
    <ToastContainer />
    <form onSubmit={onlogin} className="card p-4 shadow-sm" style={{ maxWidth: '400px' }}>
      <div className="card-body">
        <h2 className="card-title text-center mb-4">Login</h2>
  
        <div className="mb-3 position-relative">
          <label htmlFor="email" className="form-label">Email</label>
          <div className="input-group">
            <span className="input-group-text">
              <i className="fa fa-envelope"></i>
            </span>
            <input
              id="email"
              name="email"
              onChange={onchangehandller}
              value={data.email}
              type="email"
              className="form-control"
              placeholder="Your email"
              required
            />
          </div>
        </div>
  
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="form-label">Password</label>
          <div className="input-group">
            <span className="input-group-text">
              <i className="fa fa-lock"></i>
            </span>
            <input
              id="password"
              type="password"
              name="password"
              onChange={onchangehandller}
              value={data.password}
              className="form-control"
              placeholder="Password"
              required
            />
          </div>
        </div>
  
        <button type="submit" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
          <i className="fa fa-sign-in-alt me-2"></i>Login
        </button>
      </div>
    </form>
  </div>
  

  );
};

export default LoginPopup;
