import React, { useState } from "react";
import Modal from "react-modal";
import HeaderPopupForm from "../form/HeaderPopupForm";
import './Hero-banner.css'
Modal.setAppElement("#root");

const HeroBannerFive = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="hero-banner-five pb-5">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-11 col-md-10 m-auto">
            <h1
              className="hero-heading"
              data-aos="fade-up"
              data-aos-duration="1200"
              style={{ fontSize:'70px',fontWeight: "800", fontFamily: "gilroy-black" }}
            >
             Simplify Customer Management
            </h1>
            <p
              className="sub-heading fs-5 my-4"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              The Ultimate Software to maximize efficiency and accelerate Client
              Relationship Management
            </p>
          </div>
        </div>
        {/* End .row */}

        <div
          className="d-flex align-items-center justify-content-center button-group"
        
        >
          <a
            href="https://bit.ly/4gDgppF"
            className="d-flex align-items-center justify-content-center text-center btn"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="300"
            style={{ padding: "10px 20px", textDecoration: "none",backgroundColor:'rgb(88, 0, 176)' ,color:'white' }}
          >
            <strong>Book A Demo</strong>
          </a>
        </div>

        {/* End button group */}

        
        {/* End sign-in-call */}
      </div>
      {/* End .container */}

      <div
        className="img-gallery border-none"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="400"
      >
        <div className="container text-center">
          <div className="screen-container">
            <img
              src="images/assets/dashboard1.jpg"
              alt="Dashboard"
              className="main-screen"
            />
           <img
              src="images/assets/customer1.jpg"
              alt="Customers"
              className="shapes screen-one h-75 w-25"
            />
            <img
              src="images/assets/leads1.jpg"
              alt="Leads"
              className="shapes screen-two   ms-2" style={{width:'60%',height:'45%'}}
            />
          </div>
          {/* /.screen-container */}
        </div>
      </div>
      {/* /.img-gallery */}

      <img
        src="images/shape/133.svg"
        alt="shape"
        className="shapes shape-one"
      />
      <img
        src="images/shape/134.svg"
        alt="shape"
        className="shapes shape-two"
      />
      <img
        src="images/shape/135.svg"
        alt="shape"
        className="shapes shape-three"
      />
      <img
        src="images/shape/136.svg"
        alt="shape"
        className="shapes shape-four"
      />


      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner ">
          <main className="main-body box_inner modal-content clearfix">
            <button className="close" onClick={toggleModalOne}>
              <img src="images/icon/close.svg" alt="close" />
            </button>
            {/* End close icon */}

            <div className="left-side">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                      “I never dreamed about success. I worked for it.”
                    </blockquote>
                    <span className="bio">—Estée Lauder</span>
                  </div>
                </div>
                <img
                  src="images/assets/ils_18.svg"
                  alt=""
                  className="illustration mt-auto"
                />
              </div>
            </div>
            {/* /.left-side */}

            <div className="right-side">
              <h2 className="form-title">Contact us</h2>
              <HeaderPopupForm />
            </div>
            {/*  /.right-side */}
          </main>
          {/* /.main-body */}
        </div>
      </Modal>
      {/* End  Modal For Request a demo */}
    </div>
    // /.hero-banner-five
  );
};

export default HeroBannerFive;
