import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What are the pre-requisites to use ClientCtrl Software?",
    desc: `There are no mandatory requirements to use ClientCtrl Software. If you own a domain name/website, ClientCtrl can be setup for any sub-domain of your choice. For ex: clients.example.com. It can be setup on your domain itself (Ex: example.com), if you don't plan to host any website on that domain. You must add a "A Record" in your domain DNS records to point the sub-domain or domain to our server IP address.`,
    sub:`If you don't own a domain name, ClientCtrl will be setup on clientctrlcrm.com/<your_business_name>.`
  },
  {
    title: "How do I access ClientCtrl CRM?",
    desc: `You and your clients can access ClientCtrl CRM from the sub-domain of your choice. For example, if your website is 'abc.com', ClientCtrl can be setup on 'clients.abc.com' or 'crm.abc.com' or any sub-domain/domain of your choice.`,
  },
  {
    title: "From which domain email notifications are sent from my CRM?",
    desc: `If you own email accounts, for example, 'info@abc.com', using the email credentials & SMTP server details of your email, ClientCtrl can be configured to send email notifications from 'info@abc.com'.`,
  },
  {
    title: "When can I start using ClientCtrl after purchase?",
    desc: `After purchase, you must provide the domain or sub-domain where you want ClientCtrl to be setup. You must add "A Record" on your domain DNS records, pointing to our server IP address. We will do the setup within 1 day & you can start using the CRM.`,
  },
  {
    title: " Can ClientCtrl software be setup on my hosting server?",
    desc: `Yes, you must purchase 'ClientCtrl Standard' plan to host ClientCtrl software on your server. A setup charge is also applicable. We will setup the software on your server.`,
    sub:`Server Requirements: VPS or Cloud Server with LAMP Stack -
          Shared server hosting is not recommended.`
  },
  {
    title: " Is ClientCtrl a SaaS service?",
    desc: `At present, ClientCtrl is not a SaaS service, it is a hosted solution.`,
  },
  {
    title: "Can you customize the software?",
    desc: `Yes, we can customize the software as per your requirements. You must purchase 'ClientCtrl Standard' plan first. Share your requirements for a quote.`,
  },
  {
    title: "Does my CRM have your branding?",
    desc: `ClientCtrl 'STARTER' will have our branding. i.e. 'Powered by ClientCtrl' text in footer. `,
    sub:`ClientCtrl 'STANDARD' will not have our branding, it is white-label.`
  },
  
];

const FaqThree = () => {
  return (
    <div className="accordion-style-three">
      <div className="faq-wrraper">
        <Accordion allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem
              className="card"
              key={i}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
                <p>{item.sub}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqThree;
