import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import Whatsapp from "./components/Whatsapp";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";
import { useLocation, useMatch } from "react-router-dom";


const App = () => {
  const location = useLocation();

  const excludedRoutes = [
    '/blog-management/index',
    '/blog-management/blogadd',
    '/blog-management/blogview/:id',
    
  ];

  const isBlogEdit = useMatch('/blog-management/blogedit/:id');
  const isBlogView = useMatch('/blog-management/blogview/:id');

  const shouldRenderComponents = !excludedRoutes.includes(location.pathname) && !isBlogEdit  && !isBlogView;

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);
 
  return (
    <>
      <Helmet>
        <title>
          Best Small Business CRM Software | ClientCtrl
        </title>
      </Helmet>
      {/* End Seo Helmt */}

      {shouldRenderComponents && (
        <>
          {/* End Scroll top */}
          <Whatsapp />
        </>
      )}
          <ScrollToTop />

      <AllRoutes />
    </>
  );
};

export default App;
