import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
import {useNavigate } from "react-router-dom";
import { Storecontext } from "../../Context/Storecontext";
import LoginPopup from "../LoginPopup/LoginPopup";
import moment from "moment";
const Blogshow = () => {
  
  const { url, showLogin } = useContext(Storecontext);
  
  const token = localStorage.getItem("token");
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Start with page 1
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook to programmatically navigate
  const itemsPerpage = 3;
  // Function to fetch blogs with pagination
  const fetchBlogs = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/api/user/list`, {
        params: {
          page: page, // Pass the page number
          limit: itemsPerpage,
        },
        headers: { token },
      });
      if (response.data.success) {
        setBlogs(response.data.blogs);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      setError("Failed to load blogs");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch blogs when the component mounts or the page changes
  useEffect(() => {
    fetchBlogs(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, token]);

  // Function to handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleRedirectEdit = (id) => {
    // Navigate to a route with the item's ID
    navigate(`/blog-management/blogedit/${id}`);
  };
  const handleRedirectView = (id) => {
    // Navigate to a route with the item's ID
    navigate(`/blog-management/blogview/${id}`);
  };
  const removeBlog = async (Blogid) => {
    const response = await axios.delete(`${url}/api/user/remove/${Blogid}`, {
      headers: { token },
    });
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    await fetchBlogs();
  };
  const handleDeleteConfirmation = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this blog?"
    );

    if (isConfirmed) {
      removeBlog(id); // Call the delete function if confirmed
    }
  };

  return (
    <div className="container d-flex p-2 mt-4 justify-content-center align-items-center">
      {showLogin ? (
        <LoginPopup />
      ) : (
        <>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <ToastContainer />
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : (
            <div className="container">
              <div className="row align-items-center mb-4">
                {/* Blog List Heading */}
                <div className="col-md-4">
                  <h1 className="mb-0 fs-3 fw-bold">Blog List</h1>
                </div>

                {/* View Blog Button */}
                <div className="col-md-4 d-flex justify-content-center">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => navigate(`/blog`)}
                  >
                    <i className="fa fa-list mx-1"></i>
                    View Blogs
                  </button>
                </div>

                {/* Add Blog Button */}
                <div className="col-md-4 d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate(`/blog-management/blogadd`)}
                  >
                    <i className="fa fa-plus-circle mx-1"></i>
                    Add New Blog
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr.no</th>
                      <th style={{ minWidth: "300px" }}>Title</th>
                      <th>Slug</th>
                      <th style={{ minWidth: "120px" }}>Created At</th>
                      <th style={{ minWidth: "120px" }}>Updated At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blogs.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1 + (currentPage - 1) * itemsPerpage}</td>
                        <td>{item.title}</td>
                        <td>{item.url}</td>
                        <td>{moment(item.createdAt).format("DD-MMM-YY")}</td>
                        <td>{moment(item.updatedAt).format("DD-MMM-YY")}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              id={`data-${item.id}`}
                              className="btn btn-sm btn-outline-primary me-1"
                              onClick={() => handleRedirectView(item._id)}
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                            <button
                              id={`data-${item.id}`}
                              className="btn btn-sm btn-outline-primary me-1"
                              onClick={() => handleRedirectEdit(item._id)}
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                            <button
                              title="Delete"
                              type="submit"
                              onClick={() => handleDeleteConfirmation(item._id)}
                              className="btn btn-sm btn-outline-danger"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination Controls */}
              <div className="d-flex justify-content-between mt-4">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="fa fa-arrow-left"></i> Previous
                </button>

                <div className="d-flex">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className={`btn ${
                        currentPage === index + 1
                          ? "btn-primary"
                          : "btn-outline-secondary"
                      } mx-1`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>

                <button
                  className="btn btn-outline-primary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Blogshow;
