/* eslint-disable react/prop-types */

import { createContext,useEffect,useState } from "react";
export const Storecontext = createContext(null);


const StoreContextprovider = (props) => {
  const [showY, setshowY] = useState(false);
  const [showLogin,setshowLogin]=useState(true);
  const [token,settoken]=useState("");
  const url=process.env.REACT_APP_API_URL;
  
  useEffect(()=>{
  
    async function loadData(){
      
      if(localStorage.getItem('token')){
  
        settoken(localStorage.getItem('token'));
        
        setshowLogin(false);
  
      }
    }
    loadData();
  },[])
  const contextvalue = {
    showY,setshowY,url,showLogin,setshowLogin,token,settoken
  };
  return (
    <Storecontext.Provider value={contextvalue}>
      {props.children}
    </Storecontext.Provider>
  );
};

export default StoreContextprovider;
