import React, {useState} from "react";
import { Link } from "react-router-dom";
import axios from 'axios';


const socialContent = [
  {
    icon: "fa-facebook",
    color:"rgb(24,119,242)",
    link: "https://www.facebook.com/profile.php?id=61561217462079",
  },
  // {
  //   icon: "fa-twitter",
  //   link: "https://twitter.com/",
  // },
  {
    icon: "fa-linkedin",
    color:"rgb(0,99,194)",
    link: "https://www.linkedin.com/company/sash-info-services-pvt-ltd",
  },
  {
    icon: "fa-whatsapp",
    color:"rgb(37, 211, 102)",
    link: "https://wa.me/+919242137092?text=Hello!",
  },
];


const FooterTwo = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      alert('Please enter your email address.');
      return;
    }
    if (!validateEmail(email)) {
      alert('Invalid email address format.');
      return;
    }
    try {
      const response = await axios.post('https://clientctrl.app/form-submit/newsletter-form', { email });
      console.log('Response from backend:', response.data);
      alert('Thanks! You are subscribed to our newsletter.');
      setEmail('');
    } catch (error) {
      console.error('Error submitting email:', error);
      alert('Failed to submit form. Please try again later.');
    }
  };

  
  const validateEmail = (email) => {
    
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <div className="row justify-content-between">
      <div
        className="col-lg-2 col-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="/" className="footer_logo">
          <img src="/images/logo/clientctrl500.png" alt="clientctrl" />
        </a>
     
        <ul className="social-icon d-flex">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`} style={{padding:'15px',color:`${val.color}`}}></i>
              </a>
            </li>
          ))}
          
        </ul>
      </div>
      {/* /.about-widget */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="50"
      >
        <h5 className="footer-title">Product</h5>
        <ul>
          <li>
            <Link to="/clientctrl-features">
              Overview
            </Link>
          </li>
              
          <li>
              <Link to="/features-leads-management" >
                Leads & Deals
              </Link>
          </li>
          <li>
              <Link to="/features-client-management">
                  Client Management
               </Link>
          </li>
          <li>
            <Link to="/features-orders-management" >
              Orders & Invoices
            </Link>
          </li>
          <li>
          <Link to="/features-communication" >
              Communication
            </Link>
          </li>
          
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="footer-title">Useful Links</h5>
        <ul>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/terms-condition">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/terms-condition">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-condition">Refund Policy</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-3 col-sm-6 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <h5 className="footer-title">Contact Us</h5>
        <ul className="info">
          <li>
            <a href="mailto:sales@clientctrl.app">sales@clientctrl.app</a>
          </li>
          <li>
            <a href="Tel: +91-9242137092" className="mobile-num">
            <i class="fa fa-phone" style={{color:'rgb(88, 0, 176)'}}></i> <span style={{padding:'10px'}}>+91-924-213-7092</span>
            </a>
          </li> 
          
        </ul>
        
        <p style={{fontSize:'16px',paddingTop:'30px'}}>Don't miss to subscribe to our news feeds, kindly fill the form below.</p><br />
        <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ borderRadius: '5px 0 0 5px', padding: '10px',flex:'1', border: '1px solid rgb(88, 0, 176)' }}
          />
          <button type="submit" style={{ color:'whitesmoke',borderRadius: '0 5px 5px 0', padding: '10px', border: '1px solid rgb(88, 0, 176)', backgroundColor:'rgb(88, 0, 150)',width:'100%' }}>
            <i className="fa fa-send"></i>
          </button>
        </form>
        
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default FooterTwo;
