import React from "react";
import { Link } from "react-router-dom";

const FeaturesContent = [
  {
    icon: "20",
    title: "Lead Forms",
    desc: `Gather website leads directly into your CRM with embedded forms. Boost your conversion rates.`,
    dataDealy: "0",
  },
  {
    icon: "21",
    title: "Marketing Automation",
    desc: `Enhance lead nurturing with automated personalized Email/WhatsApp sequences. Close deals faster.`,
    dataDealy: "100",
  },
  {
    icon: "22",

    title: "Better Communication",
    desc: `Streamline communication with leads & clients. Improve follow-ups tracking & increase team productivity.`,
    dataDealy: "200",
  },
  {
    icon: "209",
    title: "Order Management",
    desc: `Effortlessly handle the sale of physical or digital products, services, recurring orders, payments, and shipping.`,
    dataDelay: "0",
  },
  {
    icon: "212",
    title: "Support Tickets",
    desc: `Intuitive support ticketing system that ensures your team can focus on resolving the client issues.`,
    dataDelay: "100",
  },
  {
    icon: "214",
    title: "Client Portal",
    desc: `Smooth shopping journey for clients and a portal to track their purchases, subscriptions & payments.`,
    dataDelay: "200",
  },

];

const FancyFeatureTen = () => {
  return (
    <div className="row justify-content-center mt-35 md-mt-20">
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDealy}
          key={i}
        >
          <div className="block-style-fifteen mt-40">
            <div
              className="icon d-flex align-items-center justify-content-center"
             
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h3 className="title"><span>{val.title}</span></h3>
            <p style={{fontSize:"18px"}}>{val.desc}</p>
           
          </div>
          
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTen;
