import React from "react";
import './Footer.css'
const socialContent = [
  {
    icon: "fa-facebook",
    color:"rgb(24,119,242)",
    link: "https://www.facebook.com/profile.php?id=61561217462079",
  },
  // {
  //   icon: "fa-twitter",
  //   link: "https://twitter.com/",
  // },
  {
    icon: "fa-linkedin",
    color:"rgb(0,99,194)",
    link: "https://www.linkedin.com/company/sash-info-services-pvt-ltd",
  },
  {
    icon: "fa-whatsapp",
    color:"rgb(37, 211, 102)",
    link: "https://wa.me/+919242137092?text=Hello!",
  },
];


const FooterFive = () => {
  return (
    <div className="row justify-content-center align-items-center">
      
      <div className="col-lg-4">
        <div className="logo">
        <a href="#">
          <img src="/images/logo/clientctrl500.png" alt="clientctrl" style={{width: '53%', paddingLeft: '10px'}}/>
          </a>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-4">
        <div className="title">Find us on Social Media</div>
        <ul className="d-flex justify-content-center social-icon">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a className="hoverIcon" href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* End .col */}

      <div className="col-lg-4">
        <div className="title mb-2">Get in touch</div>
        <div className="text-center ">
        <ul className="info">
          <li>
            <a href="mailto:sales@clientctrl.app"><i class="fa fa-regular fa-envelope" style={{color:'rgb(88, 0, 176)'}}></i> sales@clientctrl.app</a>
          </li>
          <li>
            <a href="Tel: +91-9242137092" className="mobile-num">
            <i class="fa fa-phone" style={{color:'rgb(88, 0, 176)'}}></i> &nbsp; <a href="https://wa.me/+919242137092?text=Hello!"><i class="fa fa-whatsapp" style={{color:'rgb(37, 211, 102)'}}></i></a> <span style={{padding:'10px'}}>+91-924-213-7092</span>
            </a>
          </li> 
          
        </ul>
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default FooterFive;
