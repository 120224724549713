import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import ClientDemo from "../../demo/ClientDemo";
import { Storecontext } from "../../../Context/Storecontext";


const MegaMenuMobile = () => {
  const{setshowY}=useContext(Storecontext);
 
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="mega-menu-wrapper">
      <div className="mega-swicher"></div>
      <div className="mob-header multi-mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      <ProSidebar
        className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}
      >
        <SidebarHeader>
          <div className="logo position-static">
            <a href="/">
              <img src="images\logo\Final-Logo-with-tagline-light.png" alt="clientctrl" style={{maxWidth:'100%'}}/>
            </a>
          </div>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close-w.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}

          {/* End logo */}
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            <MenuItem>
              <Link to="/">Home</Link>
            </MenuItem>
            {/* End Home SubMenu */}


            {/* Feature renamed as Product  */}
            <SubMenu title="Product">
              <MenuItem>
                {" "}
                <Link to="/clientctrl-features" className="dropdown-item">
                  Overview
                </Link>
              </MenuItem>
              <MenuItem>
                {" "}
                <Link to="/features-leads-management" className="dropdown-item">
                  Leads & Deals
                </Link>
              </MenuItem>
              <MenuItem>
                {" "}
                <Link to="/features-client-management" className="dropdown-item">
                  Client Management
                </Link>
              </MenuItem>
              <MenuItem>
                {" "}
                <Link to="/features-orders-management" className="dropdown-item">
                  Orders & Invoices
                </Link>
              </MenuItem>
              <MenuItem>
                {" "}
                <Link to="/features-communication" className="dropdown-item">
                  Communication
                </Link>
              </MenuItem>
            </SubMenu>
            {/* End Feautres SubMenu */}


            {/* End Docs SubMenu */}
            <MenuItem onClick={()=>setshowY(false)}>
              <Link to="/pricing" >Pricing</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/blog">Blog</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/about">About</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/contact">Contact</Link>
            </MenuItem>
            {/* <MenuItem>
              <Link to="/login">Login</Link>
            </MenuItem> */}
          
            <MenuItem>
            
              <ClientDemo />
              
            </MenuItem>
            
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default MegaMenuMobile;
