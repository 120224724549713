
// import Pricing from "../../../../components/pricing/pricing-one/Pricing";
import Pricingtwo from "../../../../components/pricing/pricing-one/Pricingtwo";
import './PricingClientCtrl.css'
const PricingClientCtrl = () => {
  return (
    <div className="main-page-wrapper p0">
      
      <div className="pricing-section-two pricing-section-two-custom">
        <div className="fancy-hero-four pt-4">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <div className="shapes shape-six"></div>
          <div className="bg-wrapper">
            <div className="container ">
              <div className="row mt-4 pt-4">
                <div className="col-xl-10 col-lg-11 m-auto pt-4">
                  <h2 className='mediaText' style={{ fontSize:'65px',fontWeight: "800", fontFamily: "gilroy-black" }}>Our Best Pricing</h2>
                </div>
                <div className="col-12 m-auto">
                  <p className="font-rubik sub-heading">
                    Budget-friendly pricing designed to meet the needs of every
                    business
                  </p>
                </div>
              </div>
              {/* End .row */}
            </div>
            {/* End .container */}
          </div>
          {/*  /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-four */}

        <div className="container">
          <div className="pricing-table-area">
            <div className="tab-content">
              <Pricingtwo />
            </div>
            <img
              src="images/shape/64.svg"
              alt="shape"
              className="shapes shape-one"
            />
          </div>
          {/*  /.pricing-table-area  */}
        </div>
      </div>
      <br />
      {/* /.pricing-section-two  */}

      {/* 	=============================================
				Fancy Text block Seven
			==============================================  */}
      <div className="fancy-text-block-seven mt-10 md-mt-60">
        <div className="bg-wrapper no-bg">
          <img
            src="images/shape/29.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/30.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/31.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/32.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="images/shape/33.svg"
            alt="shape"
            className="shapes shape-five"
          />
          
         
        </div>
        {/*  /.bg-wrapper */}
      </div>
      {/* /.fancy-text-block-seven */}

      {/* /.useable-tools-section-two */}

      {/* 	=====================================================
				FAQ Section
			===================================================== */}
     
      {/* End .faq-section */}

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
  
      {/* /.theme-footer-one */}
    </div>
  );
};

export default PricingClientCtrl;
