import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "An Easy-to-Use CRM for Small Business",
    desc: `If you have a growing business, you need to keep track of everything in your sales pipeline. From leads and orders to payments and reports, you cannot give your potential customers or existing customers any reason to choose a different business partner. ClientCtrl is a comprehensive CRM that can function as lead management software for small businesses, billing software, software for customer support and much more. With ClientCtrl, you can infuse your business with robust efficiency by streamlining all your tasks through a single platform, ultimately impacting your bottom line.    `,
    expand: "a",
  },
  {
    title: "Why is ClientCtrl the Best Value CRM for Small Business",
    desc: `Among the various CRM software available, ClientCtrl stands as the best management
    software for small businesses because of the multifaceted benefits it offers.`,
    expand: "b",
  },
  {
    title: "Effortless Lead Capture & Follow-Up",
    desc: `Never miss a lead again. Capture leads from your website, phone calls, and more.
    Automate follow-up emails and nurture leads into loyal customers.`,
    expand: "c",
  },
  {
    title: "Organized Customer Data",
    desc: `Say goodbye to scattered spreadsheets. Our CRM keeps all customer information, interactions,
    and history in one central location.`,
    expand: "d",
  },
  {
    title: "Visualize Your Sales Pipeline",
    desc: `Track deals from initial contact to closing. 
    Forecast your sales performance and identify bottlenecks with ease.`,
    expand: "e",
  },
  {
    title: "Automate Repetitive Tasks",
    desc: `Free up your time to focus on what matters. With our accounting software for small businesses,
    automate tasks like sending invoices and appointment reminders.`,
    expand: "f",
  },
  {
    title: "Delight Your Customers",
    desc: `Provide a seamless customer experience with self-service options through a customer portal on our
    customer service management software.
    Transform your business with ClientCtrl, the best value CRM for small businesses. Book a free demo to get started.`,
    expand: "g",
  },
];

const FaqClassicThree = () => {
  return (
    <div className="accordion-style-five md-mt-60">
      <div className="faq-wrraper">
        <Accordion allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0" style={{fontFamily: "gilroy-bold"}}>
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p style={{textTransform:'none'}}>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqClassicThree;
