import React from "react";
import { Helmet } from "react-helmet";
import FancyFeatureTen from "../../components/features/FancyFeatureTen";
import HeroBannerFive from "../../components/hero-banner/HeroBannerFive";
import HeaderLanding from "../../components/header/landing/HeaderLanding";
import FooterFive from "../../components/footer/FooterFive.jsx";
import PricingClientCtrllanding from "../inner-pages/pages/pricing/PricingClientCtrl_landing.jsx";
import ScrollToTop from "../../components/ScrollTopBehaviour.jsx";
import './ProductLanding.css'
const ProductLanding = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title> Product Landing</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLanding />
      <div id="home">
        <HeroBannerFive />
        <br />
        <br />
      </div>
      {/* End HeaderBannerFive */}

      {/* 	=============================================
				Fancy Feature Ten
			==============================================  */}
      <div className="fancy-feature-ten" id="features">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-md-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="title-style-six ">
                  <h2 className="mediaText"
                    style={{
                      fontSize: "65px",
                      fontWeight: "800",
                      fontFamily: "gilroy-black",
                    }}
                  >
                    What ClientCtrl Offers?
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-5 col-md-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <p className="sub-text pt-30 pb-30">
                  ClientCtrl simplifies marketing, sales, customer services &
                  content management.
                </p>
              </div>
              {/* End .col */}
            </div>
            <FancyFeatureTen />
            {/* End Fancy Feature Ten */}
          </div>
          <img
            src="images/shape/137.svg"
            alt="shape"
            className="shapes shape-one"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-feature-ten */}

      {/*   =============================================
				Fancy Text block Twenty One
			==============================================  */}

      {/* /.fancy-text-block-twentyOne */}

      {/*  =====================================================
				Counter Style Two
			===================================================== */}

      {/* /.counter-style-two */}

      {/*   =====================================================
				Fancy Feature Eleven
			===================================================== */}
      <div className="fancy-feature-eleven pt-130 md-pt-80" id="product">
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-9 m-auto">
                <div className="title-style-six text-center">
                  <h6>Benefits</h6>
                  <h2
                    className="display-4 mediaText"
                    style={{
                      fontWeight: "800",
                      fontFamily: "gilroy-black",
                    }}
                  >
                    Propel your business with ClientCtrl
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="fancy-text-block-eight pt-140 pb-100 md-pt-80 md-pb-100">
            <div className="container">
              <div
                className="block-style-six pb-100 md-pb-50"
                style={{ marginTop: "-100px", paddingBottom: "50px" }}
              >
                <div className="row">
                  <div
                    className="col-lg-5 col-md-6"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <ul className="pr-feature" style={{ color: "black" }}>
                      <li>
                        Boost your marketing and lead generation with advanced
                        tools.
                      </li>
                      <li>
                        Optimize lead management and follow-ups to drive higher
                        sales.
                      </li>
                      <li>
                        Enhance customer relationships with centralized data and
                        insights.
                      </li>
                      <li>
                        Simplify order management, billing, subscriptions, and
                        payment tracking.
                      </li>
                      <li>
                        Provide exceptional customer support through an
                        integrated ticketing system.
                      </li>
                      <li>
                        Empower your customers with a user-friendly customer
                        portal.
                      </li>
                      <li>
                        Strengthen communication with leads and clients across
                        multiple channels.
                      </li>
                      <li>
                        Enjoy a modern, intuitive user interface for seamless
                        navigation.
                      </li>
                      <li>
                        Keep your team informed and productive with real-time
                        notifications.
                      </li>
                    </ul>
                  </div>

                  <div
                className="col-lg-7 col-md-9 m-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder illustration-one">
                  <img
                    src="images/assets/clientctrloverview.png"
                    alt=""
                    className="ms-auto"
                    style={{ width: "80%", paddingBottom: "100px" }}
                  />
                  <div className="shapes shape-one"></div>
                  <div className="shapes shape-two"></div>
                  <div className="shapes shape-three"></div>
                  <div className="shapes shape-four"></div>
                  <div className="shapes shape-five"></div>
                  <img
                    src="images/shape/35.svg"
                    alt="shape"
                    className="shapes shape-six"
                  />
                  <img
                    src="images/shape/36.svg"
                    alt="shape"
                    className="shapes shape-seven"
                  />
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          src="images/shape/140.svg"
          alt="shape"
          className="shapes shape-two "
        />
        <img
          src="images/shape/139.svg"
          alt="shape"
          className="shapes shape-one"
        />
      </div>

      <div
        className="pricing-section-four position-relative"
        id="pricing"
      >
        <div className="fancy-hero-four p-0 m-0" style={{zIndex:-1}}><br /><br /></div>
        <PricingClientCtrllanding />
        <img
          src="images/shape/64.svg"
          alt="shape"
          className="shapes shape-one"
        />
      </div>
      {/*  /.pricing-section-four */}

      {/*  =====================================================
				Client Feedback Slider Four
			===================================================== */}

      {/* /.client-feedback-slider-four */}

      {/*  =====================================================
				Fancy Short Banner Six
			===================================================== */}
      <div className="fancy-short-banner-six mt-150 md-mt-80">
        <img
          src="images/shape/143.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <div className="container">
          <div className="row">
            <div
              className="col-xl-9 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-six text-center">
                <h2  className="mediaText"
                  style={{
                    fontSize: "65px",
                    fontWeight: "800",
                    fontFamily: "gilroy-black",
                  }}
                >
                  Ready to Supercharge your business?
                </h2>
              </div>

              {/* /.title-style-six */}
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
          Set up a quick 30-minute session with our product expert to dive deeper into ClientCtrl.
          </p>
          <a
            href="https://bit.ly/4gDgppF"
            className="theme-btn-seven"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
            style={{ backgroundColor: "rgb(88, 0, 176)", color: "white" }}
          >
            Book A Demo
          </a>
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-six */}

      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">
          {" "}
          © Copyright {new Date().getFullYear()} <span>ClientCtrl</span>{" "}
        </p>
      </footer>
      <ScrollToTop />

      {/*  /.theme-footer-five */}
    </div>
  );
};

export default ProductLanding;
