
import { images } from "../../../assets/images/media/images";
const PricingContent = [
  {
    packName: "Starter",
    price: "199",
    del_price: "$399",
    billCycle: " $49 per year from",
    year: "2",
    preFeatures: [
      "Unlimited Leads",
      "Unlimited Clients",
      "Unlimited Products",
      "3 Staff",
      "Single Location",
      "Deals",
      "Quotes",
      "Orders",
      "Invoices",
      "Support Tickets",
      "Analytics",
      "Client Portal",
      "Custom Domain",
      "Limited Branding",
      "5 GB Disk Space",
    ],
  },
  {
    packName: "Standard",
    price: "249 ",
    del_price: "$499",
    billCycle: " $69 per year from",
    year: "2",
    preFeatures: [
      "Unlimited Leads",
      "Unlimited Clients",
      "Unlimited Products",
      "Unlimited Staff",
      "Unlimited Locations",
      "Deals",
      "Quotes",
      "Orders",
      "Invoices",
      "Support Tickets",
      "Analytics",
      "Client Portal",
      "Custom Domain",
      "Whitelabel Branding",
      "8 GB Disk Space",
    ],
  },
];

const PricingYearlyTwo = () => {
 

  return (
    <div className="row no-gutters">
      {PricingContent.map((val, i) => (
        <div className="col-lg-6 col-sm-6 pr-bg d-flex" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price">
              <sup>$</sup>
              {val.price}{" "}
              <span style={{ fontSize: "40px" }}>
                <sub>
                  <del>{val.del_price}</del>
                </sub>
              </span>
            </div>
            <p style={{ fontSize: "26px", color: "black" }}>
              {" "}
              <b>one-time</b>
            </p>

            <div className="d-flex justify-content-center align-items-center">
              <img
                src={images.time_limit}
                alt="media"
                style={{ height: "35px", width: "35px" }}
              />
              &nbsp;
              <span style={{ fontFamily: "gilroy-semibold", color: "orange" }}>
                Limited period offer!
              </span>
            </div>
            <div className="bill-cycle" style={{ marginBottom: "5px" }}>
              {val.billCycle} {val.year}
              <sup>nd</sup> year
            </div>
            
            {!i ? (
              <div
                className="w-50  m-auto paypal-container"
                id="paypal-container-63TBMY32LV7ZE"
              ></div>
            ) : (
              <div
                className="w-50 m-auto paypal-container1"
                id="paypal-container-JYYL3G4M2SHVE"
              ></div>
            )}

            <ul style={{ color: "black" }}>
              {val.preFeatures.map((list, i) => (
                <li key={i}>
                  <hr />
                  {list}
                </li>
              ))}
              <hr />
            </ul>
          </div>
          {/* /.pr-table-wrapper  */}
        </div>
      ))}
    </div>
  );
};

export default PricingYearlyTwo;
